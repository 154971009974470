import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../assets/images/defaultIimgs/no_data.png';
import _imports_1 from '../../assets/images/defaultIimgs/indoor_not_bg.png';

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3121c918"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "no_style"
};
var _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: ""
};
var _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
import { reactive, ref, onMounted } from "vue";
export default {
  props: {
    isObj: {
      type: Object,
      default: {}
    },
    useIndoorImg: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var state = reactive({});
    onMounted(function () {});
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [!props.useIndoorImg ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3))]);
    };
  }
};