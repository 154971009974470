import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  id: "root"
};
import { defineComponent, reactive, toRefs, ref, provide, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { initWebsocket } from '@/utils/webSocket';
import { GlobalWs } from '@/utils/globalWs';
import { getToken } from "@/utils/auth";
export default {
  setup: function setup(__props) {
    // import { ElConfigProvider, ElNotification } from 'element-plus'
    var store = useStore();
    var state = reactive({
      locale: store.getters.language //汉语

    });
    var isRouterAlive = ref(true);

    var reload = function reload() {
      isRouterAlive.value = false;
      nextTick(function () {
        isRouterAlive.value = true;
      });
    };

    provide('reload', reload); //注册夸层级组件,局部刷新

    GlobalWs = initWebsocket(function (v) {
      GlobalWs = v;
    });
    onMounted(function () {}); // export default defineComponent({
    //   components: {
    //     ElConfigProvider,
    //     CimSceneVue
    //   },
    //   setup () {
    //     const store = useStore()
    //     const state = reactive({
    //       locale: store.getters.language, //汉语
    //       // locale: ''
    //       adminAreaOptions: {
    //         show: true,//是否开启行政区划图层
    //         showLabel: false,//是否显示行政区划名称
    //         current: {
    //           adcode: '4403',
    //           level: 1
    //         }
    //       },
    //       mapPackage: mapPackage.default,
    //       layers: [
    //       ],
    //     });
    //     const isRouterAlive = ref(true)
    //     const reload = () => {
    //       isRouterAlive.value = false
    //       nextTick(() => {
    //         isRouterAlive.value = true
    //       })
    //     }
    //     GlobalWs = initWebsocket(v => {
    //       GlobalWs = v
    //     })
    //     provide('reload', reload)//注册夸层级组件,局部刷新
    //     return {
    //       ...toRefs(state),
    //       isRouterAlive,
    //       markRef0,
    //       markRef1
    //     }
    //   }
    // })

    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");

      var _component_el_config_provider = _resolveComponent("el-config-provider");

      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: _unref(state).locale
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [isRouterAlive.value ? (_openBlock(), _createBlock(_component_router_view, {
            key: 0
          })) : _createCommentVNode("", true)])];
        }),
        _: 1
      }, 8, ["locale"]);
    };
  }
};